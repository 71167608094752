<template>
  <div>
    <List
      :fields="fields"
      model-name="Clientes"
      model="client"
      get_url="core/client"
      create_url="administration_create"
      :is_paginated="false"
    ></List>
  </div>
</template>

<script>
import List from "@/view/pages/crud/List";

export default {
  name: "ClientList",
  components: { List },
  data() {
    return {
      fields: [
        { key: "name", label: "Nombre" },
        { key: "paid_until", label: "Código", fieldType: "date" },
        { key: "on_trial", label: "Demo", fieldType: "boolean" },
        { key: "ruc", label: "RUC" },
        { key: "actions", label: "" },
      ],
    };
  },
};
</script>

<style scoped></style>
